// Load Active Admin's styles into Webpacker,
// see `active_admin.scss` for customization.
import "../stylesheets/active_admin";

import "@activeadmin/activeadmin";
import "activeadmin_addons"

import '@fortawesome/fontawesome-free/js/fontawesome'
import '@fortawesome/fontawesome-free/js/solid'
import '@fortawesome/fontawesome-free/js/regular'
import '@fortawesome/fontawesome-free/js/brands'

import '@activeadmin-plugins/active_admin_datetimepicker';

import 'primereact/resources/primereact.min.css';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import 'primeicons/primeicons.css';

import React from 'react';
import "preact/debug";
import { render } from 'preact';
import WorksComponent from './active_admin/components/works';

//= require jquery-ui/i18n/datepicker-ru


$(function() {
    $("#invoice_entity_id, #invoice_company_id").on('change', function() {
        var entity = $("#invoice_entity_id").val();
        if (!entity) {
            return
        }
        var company = $("#invoice_company_id").val();
        if (!company) {
            return
        }
        $.getJSON('/invoices/suggest', {company_id: company, entity_id: entity}, function(r) {
            if (r.contracts) {
                var $select = $("#invoice_contract_id")
                $select.html("<option></option>")
                r.contracts.forEach(function(v) {
                    var $opt = $("<option>")
                    $opt.attr("value", v.id)
                    $opt.text(v.name)
                    $select.append($opt)
                })
                if (r.contracts && r.contracts[0]) {
                    $("#invoice_contract_id").val(r.contracts[0].id).trigger("change")
                }
            }
            if (r.invoice) {
                $('#invoice_goods_invoice').val(r.invoice.goods_invoice);
                $('#invoice_goods_act').val(r.invoice.goods_act);
                if (r.invoice.goods_sf) {
                    $('#invoice_goods_sf').val(r.invoice.goods_sf);
                }
            }
        });
    })

    function runInvoiceCheck() {
        var $form = $("form#edit_invoice")
        if ($form.length == 0) {
            return
        }

        var $items = $form.find(".has_many_container.invoice_items fieldset")
        var $boxes = $("#invoice_goods_invoice_input, #invoice_goods_act_input, #invoice_goods_sf_input")

        if ($items.length > 0) {
            $boxes.hide()
        } else {
            $boxes.show()
        }
    }

    $(document).on('has_many_add:after', '.has_many_container', function(e, fieldset, container) {
        if (container.hasClass("invoice_items")) {
            runInvoiceCheck()
        }
    })

    runInvoiceCheck()
    initIssuesTable();
})
$(document).on('ajax:success', '.toggle-bool-form', function(event) {
    var response = event.detail[0];
    if (response.paid) {
        window.location.href = '/your/redirect/url';
    }
});

$(function() {
  $("textarea.js-copy").click(function(){
    var $t = $(this)
    $t.select();
    var val = this.innerHTML;
    document.execCommand('copy');
    this.innerHTML = "Скопировано";
    setTimeout(() => {
      this.innerHTML = val
      $t.select();
    }, 300)
  })
})

function initIssuesTable() {
    const $entity = $('#invoice_entity_id');
    const worksComponent = document.getElementById('works_component');

    $entity.on('change', function() {
        worksComponent && render(
          <WorksComponent entityIds={$entity.val()} />,
          worksComponent
        );
    })


    worksComponent && render(
      <WorksComponent entityIds={$entity.val()} />,
      worksComponent
    );

    // TODO: Переписать на нормальное
    // var $issueIdsInput = $('#invoice_issue_ids')
    //
    // if (!$issueIdsInput.length) {
    //     return;
    // }
    //
    // var issueIds = $issueIdsInput.val()
    //     .split(' ')
    //     .filter(function (item) { return item })
    //     .map(function(item) { return parseInt(item) });
    //
    // var $issueIdsSelect = $('<select multiple name="invoice[issue_ids][]" style="display: none"></select>')
    // $issueIdsSelect.insertAfter($issueIdsInput)
    // $issueIdsInput.remove();
    // var $entity = $('#invoice_entity_id');
    //
    //
    // var table = $('#issues').DataTable({
    //     select: 'multi',
    //     serverSide: true,
    //     ajax: '/rtrack/issues?entity_id=' + $entity.val(),
    //     columns: [
    //         { data: 'number', width: '80px' },
    //         { data: 'project' },
    //         { data: 'name' },
    //         { data: 'estimate', width: '50px' },
    //         { data: 'took', width: '50px' }
    //     ],
    //     buttons: [
    //         'selectRows'
    //     ]
    // })
    //
    // function addIssue(id) {
    //     if (issueIds.indexOf(id) === -1) {
    //         issueIds.push(id);
    //         commitIssues();
    //     }
    // }
    //
    // function removeIssue(id) {
    //     var index = issueIds.indexOf(id)
    //     if (index !== -1) {
    //         issueIds.splice(index, 1);
    //         commitIssues();
    //     }
    // }
    //
    // function commitIssues() {
    //     $issueIdsSelect.html("<option></option>")
    //     if (issueIds.length == 0) {
    //         $issueIdsSelect.html("<option selected='selected'></option>");
    //         return
    //     }
    //     issueIds.forEach(function(v) {
    //         $opt = $("<option>")
    //         $opt.attr("value", v)
    //         $opt.text(v)
    //         $opt.attr('selected', 'selected')
    //         $issueIdsSelect.append($opt)
    //     })
    // }
    //
    //
    // table.on('select', function ( e, dt, type, indexes ) {
    //     var data = dt.row(indexes[0]).data();
    //     addIssue(data.id);
    // })
    //
    // table.on('deselect', function ( e, dt, type, indexes ) {
    //     var data = dt.row(indexes[0]).data();
    //     removeIssue(data.id);
    // })
    //
    // table.on('draw', function () {
    //     table.rows().data().toArray().forEach(function (item, index) {
    //         if (issueIds.includes(item.id)) {
    //             table.row(index).select();
    //         }
    //     })
    // })
    //
    // $entity.on('change', function() {
    //     table.ajax.url('/rtrack/issues?entity_id=' + this.value).load()
    // })
}

import React, { useState, useEffect } from 'react';
import { TreeTable } from 'primereact/treetable';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { Fieldset } from 'primereact/fieldset';
import { InputText } from 'primereact/inputtext';

import {IssuesService, toHHMMSS} from './issue-service';

export default function ({ entityIds }) {
  const [works, setWorks] = useState([]);
  const [nodes, setNodes] = useState([]);
  const [selectedNodeKeys, setSelectedNodeKeys] = useState(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [first, setFirst] = useState(0);
  const [dates, setDates] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');


  useEffect(() => {
    getIssuesData(page);
  }, [entityIds, page, dates, globalFilter]);

  const getIssuesData = (page) => {
    IssuesService.getIssuesWithWorkByEntities(entityIds, page, {
      from: dates[0],
      to: dates[1],
      search: globalFilter
    })
      .then((data) => {
        setNodes(data.items)
        setTotalRecords(data.total_count)
      });
  }

  const rowClassName = (node) => {
    return { 'p-highlight': node.children };
  }

  const handlePageChange = (event) => {
    setPage(event.page + 1)
    setFirst(event.first);
  }

  const handleDateChange = (event) => {
    setDates(event.value);
  }

  const handleSelectionChange = (event) => {
    console.log(event.value)
    const keys = Object.keys(event.value);
    const selectedWorks = works.filter(item => keys.includes(item.key));
    const existingWorks = selectedWorks.map(item => item.key);
    setSelectedNodeKeys(event.value);
    keys.forEach(item => {
      const [issueId, workId] = item.split('-')
      if (!workId || existingWorks.includes(item)) {
        return;
      }

      const issue = nodes.find(item => item.key == issueId);

      if (!issue) {
        return;
      }

      const work = issue?.children?.find(work => work.key == item);

      selectedWorks.push({
        key: item,
        work: work.data,
        issue: issue.data
      })
    });
    setWorks(selectedWorks);
  }

  const getHeader = () => {
    return (
      <div className="p-input-icon-left">
        <i className="pi pi-search"></i>
        <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Поиск по задаче" />
      </div>
    );
  };

  let header = getHeader();

  return (
    <div className="card">
      <div className="card__dates">
        <Fieldset>
          <Calendar
            value={dates}
            selectionMode="range"
            dateFormat="dd/mm/yy"
            onChange={handleDateChange}
            readOnlyInput
            placeholder="Выберите дату"
          />
        </Fieldset>
      </div>
      <TreeTable
        lazy
        paginator
        value={nodes}
        rows={perPage}
        first={first}
        selectionMode="checkbox"
        selectionKeys={selectedNodeKeys}
        rowClassName={rowClassName}
        totalRecords={totalRecords}
        header={header}
        onPage={handlePageChange}
        onSelectionChange={handleSelectionChange}
      >
        <Column expander field="issue" header="Задача"></Column>
        <Column field="project" header="Проект" style={{ width: '300px' }}></Column>
        <Column field="employee" header="Сотрудник" style={{ width: '300px' }}></Column>
        <Column field="took_formatted" header="Время" style={{ width: '100px' }}></Column>
      </TreeTable>
      <p>Выбрано {works.length} задач, время {toHHMMSS(works.reduce((acc, cur) => acc + cur.work.took, 0))}</p>
      <h1>Данный функционал является тестовым</h1>
    </div>
  )
}

import axios from "axios";

export const toHHMMSS = (secs) => {
  var sec_num = parseInt(secs, 10)
  var hours   = Math.floor(sec_num / 3600)
  var minutes = Math.floor(sec_num / 60) % 60

  return [hours,minutes]
    .map(v => v < 10 ? "0" + v : v)
    .join(":")
}

export const IssuesService = {
  async getIssuesWithWorkByEntities(entityIds, page = 1, filters = {}) {
    const { data } = await axios.get('/rtrack/issues', {
      params: {
        entity_id: entityIds,
        page: page,
        date_from: filters.from,
        date_to: filters.to,
        search: filters.search
      }
    })

    return {
      total_count: data.total_count,
      items: this.issueDataToDataTree(data.data)
    };
  },

  issueDataToDataTree(issueData) {
    return issueData.map(record => {
      return {
        key: record.id,
        data: {
          issue: `#${record.number} ${record.name}`,
          project: record.project,
          employee: '',
          took: record.took,
          took_formatted: toHHMMSS(record.took)
        },
        children: this.workDataToDataTree(record)
      }
    })
  },

  workDataToDataTree(issue) {
    return issue.works.map(record => {
      return {
        key: `${issue.id}-${record.id}`,
        data: {
          id: record.id,
          issue: record.comment,
          project: '',
          employee: record.employee,
          took: record.took,
          took_formatted: toHHMMSS(record.took)
        }
      }
    })
  },
}
